import type { NextPage } from 'next'
import React from "react";
import Image from "next/image";
import Link from "next/link";
import Head from "next/head";

const Index: NextPage = () => {  
  return (
      <>
          <Head>
              <title>Escolha seu perfil de acesso - Plataforma de Serviços Formadores Credenciados</title>
          </Head>
          <div className="min-h-screen flex flex-column background-white justify-content-center align-items-center p-2">
              <Image
                  src="/logo-customer.png"
                  alt="logo-customer"
                  width={200}
                  height={80}
                  className="mb-5 mt-7 md:0"
              />
              <h1
                  className="font-semibold text-center text-2xl mb-4 fadein animation-duration-1000"
                  style={{color: "#293988"}}>Plataforma de Serviços Formadores Credenciados</h1>

              <div className="flex w-full relative align-items-center justify-content-center my-3 px-4 mb-6 fadein animation-duration-1000">
                  <div className="border-top-1 surface-border top-50 left-0 absolute w-full"></div>
                  <div className="px-2 z-1 surface-0 flex align-items-center">
                      <i className="pi pi-sign-in mr-2" style={{color: "#293988"}}></i>
                      <span className="font-light" style={{color: "#293988"}}>Escolha seu perfil de acesso:</span>
                  </div>
              </div>
              <div className="line mb-5"></div>
              <div className="flex flex-column md:flex-row align-items-center justify-content-around w-full md:w-8">
                  <Link
                      tabIndex={1}
                      href="/auth/login-as-manager"
                      className="opacity-to-button shadow-4 md:block md:w-5 w-9 bg-no-repeat bg-cover relative mb-5 fadein animation-duration-1000"
                      style={{
                          backgroundImage: "url('login-manager-image.png')",
                          backgroundPosition: "center",
                          borderRadius: 20,
                          height: '230px'
                      }}
                  >
                      <h3
                          className="no-underline absolute text-white"
                          style={{left: '20px', bottom: '10px'}}>
                          Chefe de Serviço
                      </h3>
                  </Link>
                  <Link
                      tabIndex={2}
                      href="/auth/login-as-fellow"
                      className="opacity-to-button shadow-4 md:block md:w-5 w-9 bg-no-repeat bg-cover relative mb-5 fadein animation-duration-1000 "
                      style={{
                          backgroundImage: "url('login-fellow-image.png')",
                          backgroundPosition: "center",
                          height: '230px',
                          borderRadius: 20
                      }}
                  >
                      <h3
                          className="no-underline absolute text-white"
                          style={{left: '20px', bottom: '10px'}}>
                          Fellow
                      </h3>
                  </Link>
              </div>
              <br/><br/>
          </div>
      </>
  );
}

export default Index
